import { cn } from "@/lib/utils";

export function LogoFullImage({ className }: { className?: string }) {
  return (
    <svg className={cn("", className)} width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6865 15.7473C12.1568 15.7464 10.663 16.2105 9.40318 17.0781C8.14336 17.9457 7.17707 19.1759 6.6325 20.6053L1.49284 34.1032C1.16658 34.961 0.999565 35.871 1 36.7887V41.7306C1 43.7312 1.79476 45.65 3.20945 47.0647C4.62413 48.4793 6.54286 49.2741 8.54353 49.2741H40.394C42.3946 49.2741 44.3134 48.4793 45.7281 47.0647C47.1427 45.65 47.9375 43.7312 47.9375 41.7306V36.7887C47.9375 35.8701 47.7699 34.9615 47.4447 34.1032L42.3016 20.6053C41.7573 19.1764 40.7915 17.9466 39.5323 17.0791C38.2732 16.2115 36.7801 15.747 35.251 15.7473H13.6865ZM11.3363 22.3957C11.5176 21.9199 11.839 21.5103 12.258 21.2212C12.6771 20.932 13.174 20.7769 13.6832 20.7763H35.251C35.7607 20.7762 36.2584 20.9311 36.6781 21.2202C37.0978 21.5094 37.4197 21.9194 37.6012 22.3957L42.7308 35.8634H34.6441C34.0923 35.8633 33.549 35.9994 33.0624 36.2597C32.5758 36.52 32.161 36.8963 31.8547 37.3553L30.4935 39.4005C30.1872 39.8595 29.7724 40.2358 29.2858 40.4961C28.7992 40.7564 28.2559 40.8925 27.7041 40.8924H21.2334C20.6816 40.8925 20.1383 40.7564 19.6517 40.4961C19.1651 40.2358 18.7503 39.8595 18.444 39.4005L17.0828 37.3553C16.7765 36.8963 16.3617 36.52 15.8751 36.2597C15.3885 35.9994 14.8452 35.8633 14.2934 35.8634H6.20671L11.3363 22.3957Z" fill="black" />
      <path d="M46.188 13.775L46.188 13.773C46.184 10.6842 44.7595 7.77613 42.3194 5.66902C39.8861 3.56782 36.6284 2.41562 33.2682 2.41235H33.2668C29.7093 2.41235 26.4356 3.54281 24.0561 5.6007C21.6766 3.54281 18.4029 2.41235 14.8455 2.41235L14.844 2.41235C11.4839 2.41562 8.22611 3.56782 5.79286 5.66902C3.35276 7.77613 1.92829 10.6842 1.92426 13.773V13.775C1.92426 16.996 3.30516 19.9573 5.22264 22.5243C7.14312 25.0954 9.67789 27.3749 12.181 29.2678C17.1737 33.0436 22.2544 35.44 22.7306 35.6581C23.1479 35.8512 23.6025 35.9475 24.0561 35.9475C24.5098 35.9475 24.9644 35.8512 25.3817 35.6581C25.8579 35.44 30.9385 33.0436 35.9313 29.2678C38.4344 27.3749 40.9691 25.0954 42.8896 22.5243C44.8071 19.9573 46.188 16.996 46.188 13.775Z" fill="black" stroke="white" stroke-width="3" />
    </svg>
  );
}
