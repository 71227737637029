import Link from "next/link";
import { LogoFullImage } from "./logo-full-image";
import { LogoImage } from "./logo-image";

export function LogoFull({ className }: { className?: string }) {
  return (
    <Link href={"/"}>
      <LogoFullImage className={className} />
    </Link>
  );
}

export function LogoOnly({ className }: { className?: string }) {
  return (
    <Link href={"/"}>
      <LogoImage className={className} />
    </Link>
  );
}
