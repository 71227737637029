"use client";

import * as React from "react";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { MenuIcon } from "lucide-react";

import { cn } from "@/lib/utils";

import { appConfig } from "@/config";

export function Footer() {
  return (
    <div className="px-5 lg:container lg:mx-auto">
      {/* <div className="flex flex-col items-center justify-between space-y-2 lg:flex-row sc">
        <div>
          <div className="flex flex-row items-center justify-center space-x-2 lg:justify-start"><SiteSocialNav links={layoutConfig.site.socialNavLinks as ISiteSocialNavLinkProps[]} /></div>
          <div className="pl-3 pt-1 text-center text-sm lg:text-left">{t(layoutConfig.site.footerSlogan)}</div>
          <div className="hidden py-1 pl-3 text-center text-sm text-muted-foreground lg:block lg:text-left">
            © {YEAR} {appConfig.name}. {t(layoutConfig.site.footerCopyright)}
          </div>
        </div>
        <div>
          <SiteFooterNav links={layoutConfig.site.footerNavLinks} />
        </div>
      </div>
      <div className="pb-1 pt-2 text-center text-sm text-muted-foreground lg:hidden">
        © {YEAR} {appConfig.name}. {t(layoutConfig.site.footerCopyright)}
      </div> */}
    </div>
  );
}
