"use client";

import { cn } from "@/lib/utils";

import { ISiteLayoutProps } from "./types";
import styles from "./index.module.css";

export function SiteLayout({ children, className }: ISiteLayoutProps) {
  return <div className={cn(styles.siteLayout, className)}>{children}</div>;
}
