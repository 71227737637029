"use client";

import { cn } from "@/lib/utils";

import { ISiteFooterProps } from "./types";
import styles from "./index.module.css";

export function SiteFooter({ children, className }: ISiteFooterProps) {
  return (
    <footer className={cn(styles.siteFooter, className)}>
      <div className={styles.siteFooterBody}>{children}</div>
    </footer>
  );
}
