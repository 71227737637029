"use client";

import { useTransition } from "react";
import { useRouter, useParams, usePathname } from "next/navigation";
import { useLocale } from "next-intl";
import { LanguagesIcon } from "lucide-react";
import { getLanguages, TLanguageCode } from "locale-lists-lib";

import { Loading } from "@/components/common/loading";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

import { appConfig } from "@/config";

export function LanguageDropDown() {
  const locale = useLocale();
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();
  const params = useParams();
  const getSupportedLanguages = getLanguages(
    appConfig.locales.supported as TLanguageCode[],
  );
  const onSelectChange = (nextLocale: string) => {
    startTransition(() => {
      // @ts-expect-error -- TypeScript will validate that only known `params`
      // are used in combination with a given `pathname`. Since the two will
      // always match for the current route, we can skip runtime checks.
      router.replace(`/${nextLocale}${pathname}`, { locale: nextLocale });
      router.refresh();
    });
  };

  if (isPending) {
    return <Loading />;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="rounded-full">
          <LanguagesIcon className="h-5 w-5" />
          <span className="sr-only">Change language</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {getSupportedLanguages.map((language, index) => (
          <DropdownMenuCheckboxItem
            key={index}
            checked={locale === language.code}
            onCheckedChange={() => onSelectChange(language.code!)}
          >
            <div>
              {language.native}{" "}
              <div className="pt-0.5 text-xs text-muted-foreground">
                {language.name}
              </div>
            </div>
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
