export const siteConfig = {
  name: "KindlyDrop",
  description: "Make a positive difference, one message at a time.",
  header: {
    navItems: [
      {
        title: "site.header.navItems.how",
        path: "/how",
      },
      {
        title: "site.header.navItems.about",
        path: "/about",
      },
    ],
  },
  footer: {
    slogan: "site.footer.slogan",
    copyright: "site.footer.copyright",
  },
};

export type SteConfig = typeof siteConfig;
