"use client";

import { cn } from "@/lib/utils";

import { ISiteHeaderProps } from "./types";
import styles from "./index.module.css";

export function SiteHeader({ children, className }: ISiteHeaderProps) {
  return (
    <header className={cn(styles.siteHeader, className)}>
      <div className={styles.siteHeaderBody}>{children}</div>
    </header>
  );
}
