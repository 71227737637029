"use client";

import { LogoFull } from "@/components/logo";
import { NavBar } from "@/components/common/navbar";
import { NavBarMobile } from "@/components/common/navbar-mobile";
import { ThemeDropDown } from "@/components/common/theme-dropdown";
import { LanguageDropDown } from "@/components/common/language-dropdown";

import { siteConfig } from "@/app/[locale]/(site)/config";

export function Header() {
  return (
    <div className="flex flex-row items-center justify-between">
      <div>
        <LogoFull className="scale-[65%]" />
      </div>
      <div className="hidden lg:block">
        <div className="flex flex-row items-center gap-4">
          <NavBar items={siteConfig.header.navItems} />
          <ThemeDropDown />
          <LanguageDropDown />
        </div>
      </div>
      <div className="lg:hidden">
        <div className="flex flex-row items-center gap-4">
          <ThemeDropDown />
          <LanguageDropDown />
          <NavBarMobile items={siteConfig.header.navItems} />
        </div>
      </div>
    </div>
  );
}
