"use client";

import { cn } from "@/lib/utils";

import { ISiteMainProps } from "./types";
import styles from "./index.module.css";

export function SiteMain({ children, className }: ISiteMainProps) {
  return <div className={cn(styles.siteMain, className)}>{children}</div>;
}
