"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import { LucideIcon } from "lucide-react";

import { buttonVariants } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";

import { isRouteActive } from "@/lib/utils";

interface INavBarProps {
  items: INavItemProps[];
}

export function NavBar({ items }: INavBarProps) {
  return (
    <NavigationMenu>
      <NavigationMenuList className="space-x-3">
        {items.map((item, index) => (
          <NavItem key={index} {...item} />
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

interface INavItemProps {
  title: string;
  path: string;
  icon?: LucideIcon;
  end?: boolean;
}

function NavItem(item: INavItemProps) {
  const t = useTranslations();
  const currentPath = usePathname() ?? "";
  const active = isRouteActive(item.path, currentPath, item.end ? 1 : 3);

  return (
    <NavigationMenuItem>
      <NavigationMenuLink asChild>
        <Link
          className={buttonVariants({ variant: active ? "default" : "ghost" })}
          href={item.path}
          passHref
        >
          {t(item.title)}
        </Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  );
}
