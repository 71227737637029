"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import { LucideIcon, MenuIcon } from "lucide-react";

import { LogoFull } from "@/components/logo";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from "@/components/ui/sheet";
import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";

import { isRouteActive } from "@/lib/utils";

interface INavBarMobileProps {
  items: INavItemProps[];
}

export function NavBarMobile({ items }: INavBarMobileProps) {
  return (
    <Sheet>
      <SheetTrigger>
        <Button variant="outline" size="icon" className="rounded-full">
          <MenuIcon className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[260px]" side={"right"}>
        <SheetHeader>
          <SheetTitle>
            <div className="mx-auto w-fit pb-5 pt-2">
              <LogoFull className="scale-[65%]" />
            </div>
          </SheetTitle>
        </SheetHeader>
        <div className={"grid gap-2.5"}>
          {items.map((item, index) => (
            <NavBarItem key={index} {...item} />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}

interface INavItemProps {
  title: string;
  path: string;
  icon?: LucideIcon;
  end?: boolean;
}

function NavBarItem(item: INavItemProps) {
  const t = useTranslations();
  const currentPath = usePathname() ?? "";
  const active = isRouteActive(item.path, currentPath, item.end ? 1 : 3);

  return (
    <SheetClose asChild>
      <Link
        href={item.path}
        className={cn(
          "!h-10 justify-start pl-4",
          buttonVariants({
            variant: active ? "default" : "outline",
            size: "sm",
          }),
        )}
      >
        {t(item.title)}
      </Link>
    </SheetClose>
  );
}
